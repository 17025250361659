
import HomePage from '../pages/home.f7.html';
import currentSlides from '../pages/current-slides.f7.html';
import currentMap from '../pages/current-map.f7.html';
import currentReservations from '../pages/current-reservations.f7.html';
import contact from '../pages/contact.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/current-slides/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-right');
      resolve({
        context: null,
        component: currentSlides,
      });
    }
  },
  {
    path: '/current-map/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-right');
      resolve({
        context: null,
        component: currentMap,
      });
    }
  },
  {
    path: '/current-reservations/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-right');
      resolve({
        context: null,
        component: currentReservations,
      });
    }
  },
  {
    path: '/contact/',
    async: function ({ app, to, resolve }) {

      app.panel.close('.panel-right');
      resolve({
        context: null,
        component: contact,
      });
    }
  },
];

export default routes;