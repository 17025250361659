/** @jsx $jsx */
import { $jsx } from 'framework7';
import Masonry from 'masonry-layout';
import Isotope from 'isotope-layout';
import imagesloaded from 'imagesloaded';
import classie from 'classie';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $update = _ref.$update,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUpdate = _ref.$onBeforeUpdate,
      $onUpdated = _ref.$onUpdated,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted,
      $store = _ref.$store;
  var bg = $store.getters.bg.value;
  $on('pageInit', function (e, page) {
    console.log('pageInit', page);
    var elem = document.querySelector('.grid');
    var iso = new Isotope(elem, {
      itemSelector: '.grid-item',
      //layoutMode: 'fitRows',
      masonry: {
        columnWidth: 250,
        isFitWidth: true,
        gutter: 5
      }
    });
    $f7.lazy.create('.grid');
    document.querySelector('.grid').style.visibility = "visible";
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page page-contact" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md color-white">Regresar</span>
          </a>
        </div>
        <div class="title color-white">Regresar al inicio...</div>

      </div>
    </div>

    <div class="toolbar toolbar-bottom" style="background:transparent;line-height: 150px;height: 150px;">

      <div class="toolbar-inner">
        <div class="navbar color-white font-family-quicksand font-weight-800" style="line-height: 67px;background: transparent !important"><img class="fab-car" src="static/images/number_web.png"/></div>
      </div>


    </div>

    <!-- Scrollable page content-->
    <div class="page-content">

      <div class="fab fab-left">
      </div>

      <div class="fab fab-right-top">
      </div>

      <div class="fab fab-right-top">

        <div class="block" style="width:100%">
          <a href="https://www.instagram.com/taxi_duvan/?r=nametag" class="external" target="_blank">
            <img class="fab-icon" src="static/images/instagram.png"/>
          </a>
          <br/>
          <a href="https://api.whatsapp.com/send?phone=34645649503" class="external" target="_blank">
            <img class="fab-icon" src="static/images/whatsapp.png"/>
          </a>
        </div>

      </div>



      <div class="fab fab-center-center">

        <img src="static/slides/24.jpeg?nocache=" width="400" style="border-radius:10px"/>

        <div class="btn-centered-contact">
          <div style="background:#000;border-radius: 5px;margin:10px; padding:10px;color:#fff">
          <a href="mailto:taxiduvanbcn@gmail.com" class="external" style="color:#fff;font-size:2rem">taxiduvanbcn@gmail.com</a>
          </div>
        </div>

      </div>


    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '3d841c4afc';
export default framework7Component;