import _defineProperty from "@babel/runtime/helpers/defineProperty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $update = _ref.$update,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUpdate = _ref.$onBeforeUpdate,
      $onUpdated = _ref.$onUpdated,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted,
      $store = _ref.$store;
  var bg = $store.getters.bg.value;
  $on('pageInit', function (e, page) {
    var _$f7$swiper$create;

    console.log('pageInit', page);
    var swiper = $f7.swiper.create('.swiper-container.reservations', (_$f7$swiper$create = {
      lazy: {
        threshold: 50,
        observer: true,
        loadPrevNext: true,
        loadPrevNextAmount: 1
      },
      breakpoints: {
        340: {
          slidesPerView: 1,
          spaceBetween: 1
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 1
        }
      }
    }, _defineProperty(_$f7$swiper$create, "lazy", true), _defineProperty(_$f7$swiper$create, "slidesPerView", 4), _defineProperty(_$f7$swiper$create, "spaceBetween", 1), _defineProperty(_$f7$swiper$create, "freeMode", false), _defineProperty(_$f7$swiper$create, "watchSlidesProgress", true), _defineProperty(_$f7$swiper$create, "watchSlidesVisibility", true), _$f7$swiper$create));
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md color-white">Regresar</span>
          </a>
        </div>
        <div class="title color-white">Regresar al inicio...</div>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">

      <div class="block">
        <div class="block text-centered color-white font-15rem font-family-quicksand font-weight-800 line-height-25 stroke-black">
          Lugares mas habituales de reserva!
        </div>
      </div>

      <div class="block">

        <!-- Stores Slides -->
          <div class="swiper-container reservations">
            <div class="swiper-wrapper">

              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/barcelona-airport.jpg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Barcelona Aeropuerto
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>


              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/girona-airport.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Girona Aeropuerto
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>


              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/girona.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Ciudad de Girona
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>              


              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/barcelona.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Ciudad de Barcelona
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>              


            </div>
          </div>
        <!-- Stores Slides -->
      </div>


      <div class="block">

        <!-- Stores Slides -->
          <div class="swiper-container reservations">
            <div class="swiper-wrapper">

              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/lloret-de-mar.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Lloret de Mar
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>     



              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/sitges.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Sitges
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    
            
              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/malgrat-de-mar.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Malgrat de Mar
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    


              <div class="swiper-slide swiper-lazy" data-background="/static/reservations/blanes.jpeg?nocache=">

                <!-- Content inside slide -->
                <div style="position: absolute;left: 0%;right: 0%;top: auto;bottom: 0%;width: 100%;height: 60px;padding: 0px;">
                  <div class="row no-gap">

                    <div class="col-100" style="background:transparent;color:#fff;text-align: left;padding-left:10px">
                    Blanes
                    </div>

                  </div>
                </div>
                <!-- Content inside slide -->

              </div>    



            </div>
          </div>
        <!-- Stores Slides -->
      </div>



      

</div>
  </div>
`
    }
    ;
}

framework7Component.id = '3d919f4b26';
export default framework7Component;