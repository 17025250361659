/** @jsx $jsx */
import { $jsx } from 'framework7';
import Masonry from 'masonry-layout';
import Isotope from 'isotope-layout';
import imagesloaded from 'imagesloaded';
import classie from 'classie';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $update = _ref.$update,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUpdate = _ref.$onBeforeUpdate,
      $onUpdated = _ref.$onUpdated,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted,
      $store = _ref.$store;
  var bg = $store.getters.bg.value;
  $on('pageInit', function (e, page) {
    console.log('pageInit', page);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page page-current-items" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md color-white">Regresar</span>
          </a>
        </div>
        <div class="title color-white">Regresar al inicio...</div>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">

    <!-- DO NOT COPY this URL. It cannot be used in your production site. -->
    <iframe src="https://snazzymaps.com/embed/312754"
            width="100%"
            height="600px"
            style="border:none;">
    </iframe>
      
    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '61ed6da4c3';
export default framework7Component;