/** @jsx $jsx */
import { $jsx } from 'framework7'; //import i18next from 'i18next';
//import detector from "i18next-browser-languagedetector";
//import Backend from 'i18next-http-backend';

function framework7Component(props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $f7router = _ref.$f7router,
      $update = _ref.$update,
      $on = _ref.$on,
      $onBeforeMount = _ref.$onBeforeMount,
      $onMounted = _ref.$onMounted,
      $onBeforeUpdate = _ref.$onBeforeUpdate,
      $onUpdated = _ref.$onUpdated,
      $onBeforeUnmount = _ref.$onBeforeUnmount,
      $onUnmounted = _ref.$onUnmounted,
      $store = _ref.$store;
  //let lang = $store.getters.lang.value;
  var lang = "es";

  if (localStorage.getItem("lang") != null) {
    lang = localStorage.getItem("lang");
    $store.state.locales = lang;
    console.log({
      lang: lang
    });
  }

  lang = lang;

  function openMenu() {
    $f7.panel.open('.panel-right');
  }

  function openSlide() {}

  var bg = $store.getters.bg.value;
  $on('pageInit', function (e, page) {
    console.log('pageInit', page);
    var num = Math.ceil(Math.random() * bg.totalCount);
    $('.page-home').css({
      'background-image': 'url(' + bg.path + num + '.jpg' + ')'
    });
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page page-home" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">

        <div class="title">
          <div class="center">
            <div class="block text-centered color-black font-1rem font-family-quicksand font-weight-800 line-height-25">Servicio de Taxi en Tordera y Barcelona y localidades circundantes! Abierto 24h</div>
          </div>
        </div>

        <div class="right">
          <a href="#" class="link icon-only panel-open" data-panel="right">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>

      </div>
    </div>

    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom" style="background:transparent;line-height: 150px;height: 150px;">

      <div class="toolbar-inner">
        <div class="navbar color-white font-family-quicksand font-weight-800" style="line-height: 67px;background: transparent !important"><img class="fab-car" src="static/images/number_web.png"/></div>
      </div>

      <div class="fab fab-center-center-home">
        <div class="block text-centered color-white font-15rem font-family-quicksand font-weight-800 line-height-25 stroke-black">Solicita un taxi  simplemente llamando!</div>
      </div>

    </div>


    <!-- Scrollable page content-->
    <div class="page-content home">

      <div class="fab fab-left">

        <div class="block" style="width:100%;top:15px">
          <a href="" @click="${openMenu}" data-id="en">
            <img class="fab-car" src="static/images/logo_wifi.png"/>
          </a>
        </div>

      </div>

      <div class="fab fab-right-top">

        <div class="block" style="width:100%">
          <a href="https://www.instagram.com/taxi_duvan/?r=nametag" class="external" target="_blank">
            <img class="fab-icon" src="static/images/instagram.png"/>
          </a>
          <br/>
          <a href="https://api.whatsapp.com/send?phone=34645649503" class="external" target="_blank">
            <img class="fab-icon" src="static/images/whatsapp.png"/>
          </a>
        </div>

      </div>


      <div class="fab fab-right-bottom">
      </div>


      <div class="fab fab-center-center">

        <div class="block" style="width:100%">
          <a href="/current-slides/">
            <img class="fab-icon-home" src="static/images/slides.svg"/>
          </a>
        </div>
        
      </div>

      <div class="fab fab-center-bottom" style="max-width:75%">

      </div>

    </div>

    <!-- Left panel with cover effect-->
    <div class="panel panel-left panel-cover theme-dark panel-init">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title"></div>
            </div>
          </div>
          <div class="page-content">
          </div>
        </div>
      </div>
    </div>
    <!-- Right panel with reveal effect-->
    <div class="panel panel-right panel-cover theme-dark">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Menu</div>
            </div>
          </div>
          <div class="page-content">
            <div class="list">
              <ul>
                <li>
                  <a href="/current-map/" class="item-content item-link">
                    <div class="item-inner">
                      <div class="item-title">Donde Estamos?</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/current-reservations/" class="item-content item-link">
                    <div class="item-inner">
                      <div class="item-title">Reservas Habituales</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/contact/" class="item-content item-link">
                    <div class="item-inner">
                      <div class="item-title">Contactame</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
  


          </div>


        </div>
      </div>
    </div>


  </div>
`
    }
    ;
}

framework7Component.id = 'db5ed518c6';
export default framework7Component;