
import { createStore } from 'framework7';

const store = createStore({
  state: {
    lang: 'en',
    bg: {
      path: "static/bg/",
      totalCount: 1
    }
  },
  getters: {
    bg({ state }) {
      return state.bg;
    },
  },
  actions: {
    addProduct({ state }, product) {
      state.products = [...state.products, product];
    },
  },
})
export default store;
